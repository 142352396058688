import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import Navbar from '../../components/navbar/Navbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

const Login = () => {
    let navigate = useNavigate();
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleForgotPassword = () => {
        setShowForgotPassword(true);
        setStatusMessage('');
      };
    const handleSendResetLink = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch(`${process.env.REACT_APP_API}/api/auth/forgot-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
          });
    
          if (response.ok) {
            setStatusMessage('A password reset link has been sent to your email.');
          } else {
            setStatusMessage('Something went wrong. Please try again.');
          }
        } catch (error) {
          console.error('Error:', error);
          setStatusMessage('An error occurred. Please try again.');
        }
      };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
    
        fetch(`${process.env.REACT_APP_API}/api/login`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                email: document.getElementById('email').value,
                password: document.getElementById('password').value
            })
        })
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => localStorage.setItem('admin', data ? data : false));
                localStorage.setItem('account', document.getElementById('email').value);
                toast.success('Logged in successfully!')
                navigate('/');
            } else if (response.status === 401) {
                toast.error('Wrong password!')
            } else if (response.status === 404) {
                toast.error('Account not found.')
            }
        })
    }

    return (
        <div className="login">
            <Navbar />
            
            <div className="login-inner" style={{ flexDirection: 'column', gap: '20px'}}>
                {!showForgotPassword && ( <><form>
                    <div>
                        <h4>Log In</h4>
                    </div>

                    <div className="input-group">
                        <label>Email</label>
                        <input type="email" name="email" id="email"></input>
                    </div>

                    <div className="input-group">
                        <label>Password</label>
                        <input type="password" name="password" id="password"></input>
                    </div>

                    <div style={{ display: 'flex', width: "100%", alignItems:"center", justifyContent:"center", gap: "20px" }}>
                        <button onClick={handleLoginSubmit}>Log In</button>
                    </div>
                </form>

                <button onClick={handleForgotPassword}>Forgot Password?</button>
                </>)}

                {showForgotPassword && (
                <div className='login-inner'>
                <form>
        <div style={{ marginTop: '20px', width: '80%', paddingBottom: '40px' }} >
          {!statusMessage && (
            <div className="input-group">
                        <label style={{ marginBottom: '20px' }}>Email</label>
                        <input                 type="email"
                        style={{ marginBottom: '20px' }}
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}></input>
                                    <div style={{ display: 'flex', width: "100%", alignItems:"center", justifyContent:"center", gap: "20px", marginBottom: '20px' }}>
                        <button onClick={(e) => handleSendResetLink(e)}>Send Reset Link</button>
                    </div>
              
          </div>
          )}

          {statusMessage && <p>{statusMessage}</p>}
        </div>
      </form>
            </div>
      )}
            </div>
        </div>
    );
}

export default Login;