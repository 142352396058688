import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import { useParams } from 'react-router-dom';

function ResetPasswordPage() {
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const params = useParams();

  useEffect(() => {
    if (params.token) {
        setToken(params.token);
    } else {
        navigate('/');
    }
}, [params])

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setStatusMessage('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password }),
      });

      if (response.ok) {
        setStatusMessage('Password successfully reset. You can now log in.');
      } else {
        const data = await response.json();
        setStatusMessage(data.message || 'Error resetting password.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('An error occurred. Please try again.');
    }
  };

  if (!token) {
    return <div>Invalid or missing token.</div>;
  }

  return (
    <div className="login">
    <Navbar />
    
    <div className="login-inner">
        <form>
        <div>
      <h2>Reset Your Password</h2>
      {!statusMessage ? (
        <div>
                                <div className="input-group" style={{ height: "30px" }}>
                        <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
                    </div>

                    <div className="input-group" style={{ height: "30px" }}>
                        <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
                    </div>


          <button onClick={(e) => handleResetPassword(e)}>Reset Password</button>
        </div>
      ) : (
        <p>{statusMessage}</p>
      )}
    </div>
        </form>
    </div>
</div>

  );
}

export default ResetPasswordPage;